import { useRouter } from 'next/router';
import * as React from 'react';
import { useGeolocated } from 'react-geolocated';
import { FaSearch } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';

import heroBannerBackground from '@/public/assets/hero-banner-bg.jpeg';

import { useAccessibilitySettings } from '@/hooks/useAccessibilitySettings';

import { logFirebaseEvent } from '@/utils/firebase.utils';

type HeroBannerProps = {
  topComponent?: React.ReactNode;
  title: string;
  description?: string;
  initialValues?: {
    searchTerm?: string | null;
  };
  className?: string;
};

function HeroBanner({
  topComponent,
  title,
  description,
  initialValues,
  className,
}: HeroBannerProps) {
  const router = useRouter();
  const latitude = router.query.latitude;
  const longitude = router.query.longitude;

  const { useHighContrast } = useAccessibilitySettings();

  const { getPosition } = useGeolocated({
    suppressLocationOnMount: true,
    onError: positionError => {
      alert(
        `Sorry, it seems like we weren't able to get your location. Please check that you have location services enabled and try again.`
      );
      console.error(positionError);
    },
    onSuccess: position => {
      router.push(
        `/restaurants/search?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`
      );
      logFirebaseEvent('click_near_me');
    },
  });

  const handleNearMeButtonClick = () => {
    getPosition();
  };

  const [searchTerm, setSearchTerm] = React.useState('');
  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (latitude && longitude) {
      if (searchTerm) {
        router.push(
          `/restaurants/search?searchTerm=${searchTerm}&latitude=${latitude}&longitude=${longitude}`
        );
      } else {
        router.push(
          `/restaurants/search?latitude=${latitude}&longitude=${longitude}`
        );
      }
    } else if (searchTerm) {
      router.push(`/restaurants/search?searchTerm=${searchTerm}`);
    } else {
      router.push('/restaurants');
    }
    logFirebaseEvent('search_submit', { searchTerm });
  };

  React.useEffect(() => {
    if (initialValues?.searchTerm) {
      setSearchTerm(initialValues.searchTerm);
    } else {
      setSearchTerm('');
    }
  }, [initialValues]);

  return (
    <section
      className={twMerge(
        'pt-12 pb-28',
        useHighContrast ? 'bg-contrast-yellow text-black' : '',
        className
      )}
      style={{
        backgroundImage: useHighContrast
          ? 'unset'
          : `url(${heroBannerBackground.src})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
      }}
    >
      <div className="container flex flex-col items-center">
        {topComponent}

        <h2
          className={twMerge(
            'text-4xl font-medium text-center',
            useHighContrast ? 'text-inherit' : 'text-primary-500',
            description ? 'mb-8' : null,
            topComponent ? 'mt-4' : null
          )}
        >
          {title}
        </h2>

        {description ? (
          <p
            className={twMerge(
              'text-xl font-light text-center max-w-[760px]',
              useHighContrast ? 'text-inherit' : 'text-white'
            )}
          >
            {description}
          </p>
        ) : null}

        <form
          onSubmit={handleFormSubmit}
          className="mt-14 w-full max-w-[1000px] flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:h-[58px]"
        >
          <button
            type="button"
            onClick={handleNearMeButtonClick}
            className={twMerge(
              'btn btn-xl rounded-[28px] px-10 h-[58px] lg:hidden',
              useHighContrast ? 'btn-contrast-yellow' : 'btn-primary',
              latitude && longitude ? 'hidden' : null
            )}
          >
            Near me
          </button>

          <input
            type="text"
            id="search-term"
            name="search-term"
            placeholder="Restaurant name or location"
            aria-label="Restaurant name or location"
            className={twMerge(
              'lg:flex-1 rounded-tr-[28px] rounded-br-[28px] lg:rounded-tr-none lg:rounded-br-none rounded-tl-[28px] rounded-bl-[28px] px-10 h-[58px] lg:h-full',
              useHighContrast
                ? 'bg-contrast-yellow text-black border border-black placeholder:text-black'
                : null
            )}
            value={searchTerm}
            onChange={handleSearchTermChange}
          />

          <button
            type="submit"
            className={twMerge(
              'btn btn-xl rounded-[28px] lg:rounded-tl-none lg:rounded-bl-none lg:rounded-tr-[28px] lg:rounded-br-[28px] px-10 h-[58px] lg:h-full',
              useHighContrast ? 'btn-contrast-yellow' : 'btn-primary'
            )}
          >
            <FaSearch className="w-4 h-4 mr-2" aria-hidden="true" />
            Search
          </button>
        </form>
      </div>
    </section>
  );
}

export default HeroBanner;
