import { StaticImageData } from 'next/image';

import affiliate1 from '@/public/assets/affiliates/1.png';
import affiliate2 from '@/public/assets/affiliates/2.png';
import affiliate3 from '@/public/assets/affiliates/3.png';
import affiliate4 from '@/public/assets/affiliates/4.jpg';
import affiliate5 from '@/public/assets/affiliates/5.jpeg';
import affiliate6 from '@/public/assets/affiliates/6.png';
import affiliate7 from '@/public/assets/affiliates/7.jpeg';

export const affiliateImages: {
  img: StaticImageData;
  alt: string;
}[] = [
  {
    img: affiliate2,
    alt: 'Nandos logo',
  },
  {
    img: affiliate3,
    alt: 'Cote Brasserie logo',
  },
  {
    img: affiliate4,
    alt: 'Fridays logo',
  },
  {
    img: affiliate5,
    alt: 'JD Wetherspoon logo',
  },
  {
    img: affiliate6,
    alt: 'Fullers logo',
  },
  {
    img: affiliate7,
    alt: 'Franca Manca logo',
  },
];

export const statistics: string[] = [
  `Only 1% of blind people read Braille in the UK. That leaves almost 2 million who don't, so how do they manage in restaurants?`,
  `87% rely on a dining companion or waiter to read them the menu for them when dining out.`,
  `91% say browsing the menu themselves makes the dining more inclusive and enjoyable.`,
];
