import type { NextPage } from 'next';
import Image from 'next/image';
import Link from 'next/link';
import { FaChevronRight } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';

import appleAppStoreLogo from '@/public/assets/app-store-logo.svg';
import iosDemoWithNandosMenuVertical from '@/public/assets/gft-ios-nandos-menu-vertical.png';
import iosDemoWithNandosMenu from '@/public/assets/gft-ios-nandos-menu.png';
import iosDemo from '@/public/assets/gft-ios.png';
import googlePlayStoreLogo from '@/public/assets/google-play-logo.png';
import logoBlack from '@/public/assets/logo-black.png';
import logo from '@/public/assets/logo.png';

import { affiliateImages, statistics } from '@/config/content.config';

import { useAccessibilitySettings } from '@/hooks/useAccessibilitySettings';

import Layout from '@/components/Layout';
import HeroBanner from '@/components/sections/HeroBanner';
import SEO from '@/components/utils/SEO';

const Home: NextPage = () => {
  const { useHighContrast } = useAccessibilitySettings();

  return (
    <Layout>
      <SEO title="Home" description="Home" />

      <HeroBanner
        topComponent={
          <h1>
            <Image
              src={useHighContrast ? logoBlack : logo}
              alt="goodfoodtalks header logo"
            />
          </h1>
        }
        title="Opening menus for everybody"
        description="GoodFoodTalks makes it easy for visually impaired diners to eat out at thousands of UK restaurants by providing accessible menus right here on our website and on our iPhone app."
      />

      <section
        className={twMerge(
          'pt-20',
          useHighContrast ? 'bg-contrast-yellow text-black' : 'bg-[#fbfbee]'
        )}
      >
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-12 lg:gap-8 xl:gap-16">
            <div className="lg:col-span-7 xl:col-span-6">
              <div className="pb-20">
                <h2 className="text-3xl text-[#15343c] pb-8">
                  Menus on the go. Download the GoodFoodTalks app today
                </h2>

                <p className="text-xl font-light pb-8">
                  Take Goodfoodtalks with you on the move with our iOS mobile
                  application, making the experience even more accessible and
                  even more convenient. With over 5000 restaurants to choose
                  from throughout the UK, you can independently browse the menu
                  at your favourite restaurant (or request that they sign up if
                  they arent already!).
                </p>

                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
                  <a
                    href="https://itunes.apple.com/gb/app/good-food-talks/id995331570?mt=8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={appleAppStoreLogo}
                      alt="Get Good Food Talks on the App Store"
                      width="160px"
                      height="54px"
                    />
                    <span className="sr-only">
                      Get Good Food Talks on the App Store
                    </span>
                  </a>

                  <a
                    href="https://play.google.com/store/apps/details?id=uk.co.gearedapp.gft&gl=GB"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={googlePlayStoreLogo}
                      alt="Get Good Food Talks on Google Play"
                      width="181px"
                      height="54px"
                    />
                    <span className="sr-only">
                      Get Good Food Talks on Google Play
                    </span>
                  </a>
                </div>
              </div>
            </div>

            <div className="hidden lg:flex items-end lg:col-span-5 xl:col-span-6">
              <div className="flex-1 max-w-[90%] xl:max-w-[330px] 2xl:max-w-[330px] mx-auto">
                <Image
                  src={iosDemo}
                  alt="Good Food Talks mobile app screenshots"
                  layout="responsive"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className={twMerge(
          'py-6',
          useHighContrast ? 'bg-contrast-yellow text-black' : 'bg-[#ebeac8]'
        )}
      >
        <div className="max-w-[900px] xl:max-w-none mx-auto">
          <div className="flex flex-col justify-center items-center sm:flex-row sm:flex-wrap gap-y-6 sm:space-x-10">
            {affiliateImages.map(affiliate => (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                key={`${affiliate.img.src}`}
                src={affiliate.img.src}
                alt={affiliate.alt}
                className="h-[80px] md:h-[110px] lg:h-[120px] xl:h-[100px] 2xl:h-[130px] max-w-[140px] 2xl:max-w-[230px] object-contain"
              />
            ))}
          </div>
        </div>
      </section>

      <section
        className={twMerge(
          'relative pt-20 pb-20',
          useHighContrast ? 'bg-contrast-yellow text-black' : 'bg-[#fbfbee]'
        )}
      >
        <div className="container">
          <div className="relative 2xl:grid 2xl:grid-cols-2">
            <div className="md:max-w-[450px] xl:max-w-[620px] 2xl:max-w-[780px] pb-10 md:pb-0 2xl:flex 2xl:flex-col 2xl:justify-center">
              <h2 className="text-3xl text-[#15343c] pb-8">
                Good Food Talks is making good food more accessible to everyone
              </h2>
              <p className="text-xl font-light pb-8 md:max-w-[320px] 2md:max-w-[500px] lg:max-w-full">
                Goodfoodtalks is a revolution in dining out for blind and
                visually impaired people throughout the UK. Regain your
                independence by browsing and listening to the menus at your
                favourite restaurants through a fully accessible website and
                mobile application.
              </p>
              <Link prefetch={false} href="/restaurants">
                <a
                  className={twMerge(
                    'btn btn-xl mt-8 flex sm:inline-flex items-center self-start',
                    useHighContrast ? 'btn-contrast-yellow' : 'btn-primary'
                  )}
                >
                  Browse restaurants
                  <FaChevronRight className="h-5 w-5 ml-4" aria-hidden="true" />
                </a>
              </Link>
            </div>

            <div className="hidden 2xl:flex justify-center">
              <div className="relative w-[580px] h-[480px]">
                <Image
                  src={iosDemoWithNandosMenu}
                  alt="Good Food Talks mobile restaurant menu"
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="block 2xl:hidden relative md:absolute right-0 -bottom-10 xl:-bottom-20 w-full h-[300px] md:w-[340px] md:h-[380px] lg:w-[500px] lg:h-[520px] z-10">
          <Image
            src={iosDemoWithNandosMenuVertical}
            alt="Good Food Talks mobile restaurant menu"
            layout="fill"
            objectFit="contain"
          />
        </div>
      </section>

      <section
        className={twMerge(
          'relative pt-20 lg:pt-[124px] 2xl:pt-20 pb-20',
          useHighContrast ? 'bg-contrast-yellow text-black' : 'bg-[#f6f4df]'
        )}
      >
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <div>
              <h2 className="text-3xl text-[#15343c] pb-8">
                A New Opportunity for Restaurant Owners
              </h2>
              <p className="text-xl font-light pb-8">
                Good Food Talks is already trusted across more than 5000 venues
                by some of the UK’s leading restaurant chains. We can help you
                make your menu completely accessible for people with visual
                impairments and other reading difficulties for a low monthly
                fee. Click through to see more about the{' '}
                <Link prefetch={false} href="/benefits">
                  <a
                    className={twMerge(
                      useHighContrast
                        ? 'link-contrast-underline'
                        : 'link-primary'
                    )}
                  >
                    benefits for restaurant owners.
                  </a>
                </Link>
              </p>
              <Link prefetch={false} href="/benefits">
                <a
                  className={twMerge(
                    'btn btn-xl mt-8 flex sm:inline-flex items-center self-start',
                    useHighContrast ? 'btn-contrast-yellow' : 'btn-primary'
                  )}
                >
                  View benefits
                  <FaChevronRight className="h-5 w-5 ml-4" aria-hidden="true" />
                </a>
              </Link>
            </div>

            <div className="flex flex-col justify-center">
              <ul className="space-y-6 md:flex md:flex-col md:items-end">
                {statistics.map((statistic, index) => (
                  <li
                    key={statistic}
                    className={twMerge(
                      'p-6 border',
                      useHighContrast
                        ? 'bg-contrast-yellow border-black'
                        : 'border-[#f1eec9] shadow-sm bg-white',
                      index % 2 === 0
                        ? 'md:max-w-[340px] lg:max-w-[430px] xl:max-w-[90%] 2xl:max-w-full'
                        : 'md:max-w-[270px] lg:max-w-[350px] xl:max-w-[80%] 2xl:max-w-[80%]'
                    )}
                  >
                    <p className="font-light text-center">{statistic}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Home;
