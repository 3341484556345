import Head from 'next/head';

import { siteConfig } from '@/config/site.config';

export interface SEOProps {
  title: string;
  description?: string | null;
  socialImageSrc?: string | null;
}

function SEO({ title: pageTitle, description, socialImageSrc }: SEOProps) {
  const {
    title: siteTitle,
    description: siteDescription,
    twitterCreator,
  } = siteConfig;

  const title = `${pageTitle} | ${siteTitle}`;
  const metaDescription = description ?? siteDescription;

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />

      <link rel="icon" href="/favicon.ico" />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:site_name" content={siteTitle} />

      <meta property="twitter:card" content="summary" />
      <meta property="twitter:creator" content={twitterCreator} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={metaDescription} />

      {socialImageSrc ? (
        <meta name="twitter:image" content={socialImageSrc} />
      ) : null}
    </Head>
  );
}

export default SEO;
